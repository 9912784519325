<script>
import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
	components: {
	},
	data() {
		return {
			hash: null,
			credentials: null,
			username: null,
			password: null,
		};
	},
	created() {
		this.setLoading(false);
		this.cleanErrors();
		
	},
	computed: {
		...mapGetters({
			isLoading: "loading/isLoading",
			isAdmin: "auth/readIsAdmin",
		}),
	},
	methods: {
		...mapMutations("loginStore", ["SET_HASH","SET_CREDENTIALS","SET_ERROR"]),
		...mapMutations({
			//LOGIN_DATA
			setLoading: "loading/SET_LOADING",
		}),
		...mapActions("loginStore", ["loginActions"]),
		...mapActions({
			getAuth: "auth/getUser",
		}),
		getImage(image) {
			return `${require(`@/assets/images/views/${this.$route.name.toUpperCase()}/${image}`)}`;
		},
		cleanErrors() {
			this.SET_ERROR(null);
		},
	},
};
</script>

<template>
	<div class="flex flex-col justify-center items-center h-full layout">
		<img class="message" :src="getImage('tpremia-comingsoon3.png')" alt="">
		<div class="bg-1"></div>
		<div class="bg-2"></div>
	</div>
</template>
<style lang="scss" scoped>
.layout {
	height: 100vh;
	font-size: 20px;
	padding: 0;
	display:flex;
	justify-content: center;
	overflow: hidden;
	position: relative;
}

.bg-1, .bg-2{
	position: absolute;
	top: 0;
	height: 100%;
	z-index: 1;
}

.bg-1{
	background: rgb(140,123,31);
	background: linear-gradient(90deg, rgba(140,123,31,1) 0%, rgba(211,183,93,1) 100%);
	width: 60.40%;
	left: 0;
}
.bg-2{
	background: white;
	width: 39.55%;
	right: 0;
}

.message{
	position: absolute;
	z-index: 2;
	display: block;
}

img{
	width: 100%;
}

.image {
	height: 40vh;
}
</style>
